// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

$(document).ready(function () {

    //controlling each section expanding/contracting upon clicking 
    $(document).on('click', function (event) {
        target = $(event.target); 
        containers = document.getElementsByClassName("container")  
        if (target.hasClass("default")|| target.parentsUntil().hasClass("default")) {
            if(!target.hasClass('container')){
                target = target.closest("div")
            }
            content = target.children('.hidden')
           $("div.container").removeClass('default');
           $("div.container").addClass('notFocused');
           target.removeClass('default')
           target.addClass('focused')
           target.removeClass('notFocused')
           content.removeClass('hidden')
           content.addClass('shown')
           console.log("1")
        }
        else if (target.hasClass("focused")||target.parentsUntil().hasClass('focused')) {
            if(!target.hasClass('container')){
                target = target.closest("div")
            }
            $("div.container").removeClass('notFocused');
            $("div.container").removeClass('focused');
            $("div.shown").addClass('hidden');
            $("div.shown").removeClass('shown');
            $("div.container").addClass('default');
            console.log("2")
            }
        else if (target.hasClass("notFocused")) {
            if(!target.hasClass('container')){
                target = target.closest("div")
            }
            content = target.children('.hidden')
            $("div.container").removeClass('focused');
            $("div.container").addClass('notFocused');
            $("div.shown").addClass('hidden');
            $("div.shown").removeClass('shown');
            target.removeClass('notFocused')
            target.addClass('focused')
            content.removeClass('hidden')
            content.addClass('shown')
            console.log("3")
        }

   });
 }
)